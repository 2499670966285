






















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CouponTutorial extends Vue {
  tutorialKeys = Array.from({ length: 4 }, (_, i) => `COUPON_TUTORIAL_STEP${i + 1}`)
}
